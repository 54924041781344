var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isShowAct &&
      _vm.isAct &&
      _vm.marketGroup.pop_banner &&
      _vm.marketGroup.pop_banner.length > 0
        ? _c("div", { staticClass: "act-pop" }, [
            _c(
              "div",
              { staticClass: "pic", attrs: { "bi-route": "chuchuang" } },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "jump",
                        rawName: "v-jump",
                        value: _vm.marketGroup.pop_banner[0],
                        expression: "marketGroup.pop_banner[0]"
                      }
                    ],
                    attrs: {
                      target: "_blank",
                      "bi-click": "go",
                      "data-title": _vm.marketGroup.pop_banner[0].title,
                      "data-url": _vm.marketGroup.pop_banner[0].url,
                      "data-weizhi": "pop_banner"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.marketGroup.pop_banner[0].img }
                    })
                  ]
                ),
                _c(
                  "span",
                  { staticClass: "close", on: { click: _vm.handleHidePopAct } },
                  [_c("icon-font", { attrs: { type: "icon-hide" } })],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _vm.isShowActFloat &&
      _vm.isAct &&
      _vm.marketGroup.bottom_banner &&
      _vm.marketGroup.float_banner.length > 0
        ? _c("div", { staticClass: "act-float" }, [
            _c(
              "div",
              { staticClass: "pic", attrs: { "bi-route": "chuchuang" } },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "jump",
                        rawName: "v-jump",
                        value: _vm.marketGroup.float_banner[0],
                        expression: "marketGroup.float_banner[0]"
                      }
                    ],
                    attrs: {
                      target: "_blank",
                      "bi-click": "go",
                      "data-title": _vm.marketGroup.float_banner[0].title,
                      "data-url": _vm.marketGroup.float_banner[0].url,
                      "data-weizhi": "float_banner"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.marketGroup.float_banner[0].img }
                    })
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "close",
                    on: { click: _vm.handleHideFloatAct }
                  },
                  [_c("icon-font", { attrs: { type: "icon-hide" } })],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _vm.isShowActBottom &&
      _vm.isAct &&
      _vm.marketGroup.bottom_banner &&
      _vm.marketGroup.bottom_banner.length > 0
        ? _c("div", { staticClass: "act-bottom" }, [
            _c(
              "div",
              { staticClass: "pic", attrs: { "bi-route": "chuchuang" } },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "jump",
                        rawName: "v-jump",
                        value: _vm.marketGroup.bottom_banner[0],
                        expression: "marketGroup.bottom_banner[0]"
                      }
                    ],
                    attrs: {
                      target: "_blank",
                      "bi-click": "go",
                      "data-title": _vm.marketGroup.bottom_banner[0].title,
                      "data-url": _vm.marketGroup.bottom_banner[0].url,
                      "data-weizhi": "bottom_banner"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.marketGroup.bottom_banner[0].img }
                    })
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "close",
                    on: { click: _vm.handleHideBottomAct }
                  },
                  [_c("icon-font", { attrs: { type: "icon-hide" } })],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _c("ActUserModal"),
      _c(
        "div",
        { staticClass: "scroll-top", attrs: { "bi-route": "scrollRight" } },
        [
          _c("ul", { staticClass: "float-tool" }, [
            _c(
              "li",
              {
                staticClass: "modal_container_one_shop",
                attrs: { "bi-click": "oneRightBtn" },
                on: { click: _vm.handleOpenAtc }
              },
              [_vm._m(0), _c("div", { staticClass: "oneShop" })]
            ),
            _c(
              "li",
              {
                class:
                  "zsMarket_wrap" +
                  (_vm.showZSMarket ? "_act" : "") +
                  " modal_container_hover"
              },
              [
                _vm._m(1),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showZSMarket,
                        expression: "showZSMarket"
                      }
                    ],
                    staticClass: "modal_container_two"
                  },
                  [
                    _c("div", [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href:
                              "https://ai.taobao.com/?pid=" +
                              _vm.config.taobao.pid +
                              "&union_lens=lensId%3APUB%401657702458%40210549af_097f_181f6c425a3_0403%4001"
                          }
                        },
                        [
                          _c("img", {
                            attrs: { src: require("./images/tb.png") }
                          })
                        ]
                      ),
                      _vm._m(2),
                      _vm._m(3)
                    ])
                  ]
                )
              ]
            ),
            _c("li", { on: { click: _vm.handleAddPanel } }, [
              _c("img", {
                attrs: { src: require("./images/sc.png"), alt: "" }
              }),
              _c("p", [_vm._v("收藏")]),
              _vm.showSCModal
                ? _c("img", {
                    staticClass: "sc_modal",
                    attrs: { src: require("./images/sc_modal.png"), alt: "" }
                  })
                : _vm._e()
            ]),
            _vm._m(4),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showReturnTop,
                    expression: "showReturnTop"
                  }
                ],
                staticClass: "to_top",
                on: {
                  click: function($event) {
                    return _vm.handleToTop()
                  }
                }
              },
              [
                _c("img", {
                  staticStyle: { "padding-top": "12px" },
                  attrs: { src: require("./images/top.png"), alt: "" }
                })
              ]
            )
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showQRCode,
                  expression: "showQRCode"
                }
              ],
              staticClass: "QRCode",
              on: { click: _vm.handleQrcode }
            },
            [_vm._m(5)]
          )
        ]
      ),
      _vm.isOneShop ? _c("Activity") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("p", [_vm._v("0元")]), _c("p", [_vm._v("免单")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("p", [_vm._v("热门")]), _c("p", [_vm._v("商城")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { target: "_blank", href: "https://u.jd.com/fMf83T6" } },
      [_c("img", { attrs: { src: require("./images/jd.png") } })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          target: "_blank",
          href: "https://t.vip.com/Hrxkjg3Sl77?chanTag=gouwu360&aq=1"
        }
      },
      [_c("img", { attrs: { src: require("./images/wph.png") } })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "service" }, [
        _c("img", { attrs: { src: require("./images/kf.png"), alt: "" } }),
        _c("p", [_vm._v("客服")]),
        _c("div", { staticClass: "codeCon" }, [
          _c("div", { staticClass: "code" }, [
            _c("img", {
              attrs: { src: require("./images/code.png"), alt: "" }
            }),
            _c("p", [_vm._v("扫码联系客服")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "code", attrs: { id: "scrollQrcode" } }),
      _vm._v(" 扫码分享到-微信 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }