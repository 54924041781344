<!--
 * @Author: your name
 * @Date: 2021-08-12 09:15:44
 * @LastEditTime: 2021-11-11 17:00:44
 * @LastEditors: Please set LastEditors 
 * @Description: In User Settings Edit
 * @FilePath: /www/dtk_vue_cms_server_v2/src/components/IntersectionOberverLoading/index.vue
-->
<template>
  <div>
    <div class="act-pop" v-if="isShowAct && isAct && marketGroup.pop_banner && marketGroup.pop_banner.length > 0" >
      <div class="pic" bi-route="chuchuang">
        <a 
          v-jump="marketGroup.pop_banner[0]" 
          target="_blank"
          bi-click="go"
          :data-title="marketGroup.pop_banner[0].title"
          :data-url="marketGroup.pop_banner[0].url"
          data-weizhi="pop_banner"
        >
          <img :src="marketGroup.pop_banner[0].img" />
        </a>
        <span class="close" @click="handleHidePopAct"><icon-font type="icon-hide" ></icon-font></span>
      </div>
    </div>
    <div class="act-float" v-if="isShowActFloat && isAct && marketGroup.bottom_banner && marketGroup.float_banner.length > 0" >
      <div class="pic" bi-route="chuchuang">
        <a 
          v-jump="marketGroup.float_banner[0]" 
          target="_blank"
          bi-click="go"
          :data-title="marketGroup.float_banner[0].title"
          :data-url="marketGroup.float_banner[0].url"
          data-weizhi="float_banner"
        >
          <img :src="marketGroup.float_banner[0].img" />
        </a>
        <span class="close" @click="handleHideFloatAct"><icon-font type="icon-hide" ></icon-font></span>
      </div>
    </div>

    <div class="act-bottom" v-if="isShowActBottom && isAct && marketGroup.bottom_banner && marketGroup.bottom_banner.length > 0" >
      <div class="pic" bi-route="chuchuang">
        <a 
          v-jump="marketGroup.bottom_banner[0]" 
          target="_blank"
          bi-click="go"
          :data-title="marketGroup.bottom_banner[0].title"
          :data-url="marketGroup.bottom_banner[0].url"
          data-weizhi="bottom_banner"
        >
          <img :src="marketGroup.bottom_banner[0].img" />
        </a>
        <span class="close" @click="handleHideBottomAct"><icon-font type="icon-hide" ></icon-font></span>
      </div>
    </div>

    <!-- 首次进入网站弹窗 -->
    <ActUserModal />

    <div class="scroll-top" bi-route="scrollRight">
      <ul class="float-tool">

        <li class="modal_container_one_shop"  bi-click="oneRightBtn" @click="handleOpenAtc">
            <a>
              <p>0元</p>
              <p>免单</p>
            </a>
            <div class="oneShop"></div>
          </li>

        <li :class="`zsMarket_wrap${showZSMarket ? '_act' : ''} modal_container_hover` ">
          <a>
            <p>热门</p>
            <p>商城</p>
          </a>
          <div v-show="showZSMarket" class="modal_container_two">
            <div>
              <a target="_blank" :href="`https://ai.taobao.com/?pid=${config.taobao.pid}&union_lens=lensId%3APUB%401657702458%40210549af_097f_181f6c425a3_0403%4001`"><img src="./images/tb.png" /></a>
              <a target="_blank" href="https://u.jd.com/fMf83T6"><img src="./images/jd.png" /></a>
              <a target="_blank" href="https://t.vip.com/Hrxkjg3Sl77?chanTag=gouwu360&aq=1"><img src="./images/wph.png" /></a>
            </div>
          </div>
        </li>
        <li @click="handleAddPanel">
          <img src="./images/sc.png" alt />
          <p>收藏</p>
          <img class="sc_modal" v-if="showSCModal" src="./images/sc_modal.png" alt="">
        </li>
        <!-- <li class="ft-share">
          <img src="https://cmsstaticnew.dataoke.com//images/home/share.png?v=202111051520" alt />
          <p>分享</p>
          <div class="share-platform">
            <div class="share-platform-r">
              <div class="bshare-custom bdsharebuttonbox bdshare-button-style1-32">
                <a title="分享到微信" class="bshare-weixin bds_weixin" @click="handleShare('weixin')"></a>
                <a title="分享到新浪微博" class="custom_share bds_tsina" @click="handleShare('weibo')" style="display: inline-block;"></a>
                <a title="分享到QQ好友" class="bshare-qqim bds_sqq"  @click="handleShare('qq')" ></a>
                <a title="分享到QQ空间" class="custom_share bds_qzone"  @click="handleShare('qqkj')" style="display: inline-block;"></a>
              </div>
            </div>
          </div>
        </li> -->
        <li>
          <div class="service">
            <!-- target="_blank" -->
            <!-- :href="`http://wpa.qq.com/msgrd?v=3&amp;uin=${config.qq}&amp;site=qq&amp;menu=yes`" -->
            <img src="./images/kf.png" alt />
            <p>客服</p>
            <div class="codeCon">
              <div class="code">
                <img src="./images/code.png" alt />
                <p>扫码联系客服</p>
              </div>
            </div>
          </div>
        </li>
        
        
        <li
          class="to_top"
          @click="handleToTop()"
          v-show="showReturnTop"
        >
          <img
            style="padding-top: 12px;"
            src="./images/top.png"
            alt
          />
        </li>
      </ul>
      <div v-show="showQRCode" class="QRCode" @click="handleQrcode" >
        <div>
          <div class="code" id="scrollQrcode"></div>
          扫码分享到-微信
        </div>
      </div>
    </div>
    <Activity v-if="isOneShop"></Activity>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconFont from "@/components/IconFont.vue";
import ActUserModal from "@/components/ActUserModal/index.vue";
import { GetCookie, SetCookie } from "@/utils/utils";
import {importCDN} from "@/utils/utils";

export default {
  data() {
    return {
      isOneShop:!GetCookie('CMS-ACT-ONE-SHOP'),
      isAct:false,
      isShowActRight:!GetCookie('CMS-ACT-RIGHT'),
      isShowActLeft:!GetCookie('CMS-ACT-LEFT'),
      isShowAct:!GetCookie('CMS-ACT-POP'),
      isShowActFloat:!GetCookie('CMS-ACT-FLOAT'),
      isShowActBottom:!GetCookie('CMS-ACT-BOTTOM'),
      showPop: false,
      showReturnTop: false,
      pageY: 400,
      imgItem: {},
      showQRCode:false,
      showZSMarket:true,//掌上商城二维码显示
      showSCModal:!localStorage.getItem('SC-MODAL') // 收藏提示显示
    };
  },
  name: "",
  props: {
    onScroll: Function // 到底部回调
    // marketGroup: Object,
  },
  components: {
    IconFont,
    ActUserModal,
    Activity: () => import("./activity.vue")
  },
  computed: {
    ...mapGetters(["marketGroup", "config"])
  },
  methods: {
    handleOpenAtc(){
      this.$router.push({
        path: `/zero`,
        query: {
        },
      });
    },
    handleShowZSMarket(){
      this.showZSMarket = !this.showZSMarket
    },
    createZSQrcode(){
      let dom = document.getElementById("zsQrcode")
      if (!dom) {
        return
      }
      new QRCode(dom,{
          text:window.location.origin,
          width: 100,
          height: 100,
          colorDark : "#000000",
          colorLight : "#ffffff",
          // correctLevel : QRCode.CorrectLevel.H
      });  // 设置要生成二维码的链接
    },
    handleHideBottomAct(){
      SetCookie('CMS-ACT-BOTTOM',1,24);
      this.isShowActBottom = false;
    },
    handleHideFloatAct(){
      SetCookie('CMS-ACT-FLOAT',1,24);
      this.isShowActFloat = false;
    },
    handleHidePopAct(){
      SetCookie('CMS-ACT-POP',1,24);
      this.isShowAct = false;
    },
    handleQrcode(){
      this.showQRCode = false;
    },
    handleShare(type){
      let url = '';
      switch (type) {
        case 'qq':
          url = `https://connect.qq.com/widget/shareqq/index.html?url=${encodeURIComponent(window.location.href)}&desc=${this.config.descript}%20&pics=&site=bshare`;
          break;
        case 'weibo':
          url = `https://service.weibo.com/share/share.php?appkey=583395093&title=${document.title}&url=${encodeURIComponent(window.location.href)}&source=dtk&desc=${this.config.descript}&sudaref=${encodeURIComponent(this.config.domian)}&display=0&retcode=6102#_loginLayer_1636534892722`;
          break;
        case 'qqkj':
          url = `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?title=${encodeURIComponent(document.title)}&url=${encodeURIComponent(window.location.href)}&source=dtk&desc=${encodeURIComponent(this.config.descript)}&summary=${encodeURIComponent(this.config.descript)}`;
          break;
        default:

          break;
      }
      if(url){
           window.open(
            url,
            "newwindow",
            "height=600, width=800, top=300, left=500, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
        );
      }else{
        if(!this.loadQRCode){
          new QRCode(document.getElementById("scrollQrcode"),{
              text:window.location.href,
              width: 180,
              height: 180,
              colorDark : "#000000",
              colorLight : "#ffffff",
              correctLevel : QRCode.CorrectLevel.H
          });  // 设置要生成二维码的链接
          this.loadQRCode = true;
        }
        
        this.showQRCode = true;
      }
      
      
    },
    handleAddPanel(){
      localStorage.setItem('SC-MODAL',1);
      this.showSCModal = false;
      try {
          window.external.addFavorite(this.config.title, window.location.href)
      } catch (e) {
          try {
              window.sidebar.addPanel(this.config.title, window.location.href, "")
          } catch (e) {
              alert('加入收藏失败，请使用Ctrl+D进行添加')
          }
      }
    },
    handleToTop() {
      try {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
       });
      } catch (error) {
        window.scrollTo(0,0);  
      }
    },
    scrollToTop() {
      window.scrollY > this.pageY
        ? (this.showReturnTop = true)
        : (this.showReturnTop = false);
    }
  },
  mounted() {
    // importCDN('https://cmsstaticnew.dataoke.com//lib/bshare/buttonLite.js')

    
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  watch: {
    $route(state){
      if(["/index/index", "/"].filter(res => res == state.path)[0]){
        this.isAct = true;
      }else if(this.isAct){
        this.isAct = false;
      }
      // console.log(state);
    },
    marketGroup: function(state) {
      if (state.popup_ads && state.popup_ads.length) {
        let popUp = GetCookie("popUp");
        if (!(popUp === "11")) {
          let image = new Image();
          image.src = state.popup_ads[0].img;
          image.onload = () => {
            this.imgItem = state.popup_ads[0];
            this.showPop = true;
          };
          // image.onerror = () => {
          //   reject();
          // };
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// 弹窗
.act-pop{
  position: fixed; left: 0; top: 0; z-index: 8888;
  width: 100%; height: 100%;
  display: flex; align-items: center; justify-content: center;
  background: rgba(0, 0, 0, .7);
  img{
    // max-height: 80%;
    width: 525px;
    height: 525px;
  }
  .pic{
    position: relative; z-index: 0;
    .close{
      position: absolute; color: #fff; top: 0; right: 0; z-index: 0; cursor: pointer;
      i{ font-size: 30px;}
    }
  }
}
// 右侧浮窗
.act-float{
  position: fixed; right: 10px; bottom: 40%; z-index: 100;
  .pic{
    display: block; position: relative; z-index: 0;
    .close{
      position: absolute; color: #fff; top: 0; right: 0; z-index: 0; cursor: pointer;
      i{ font-size: 16px; color: #999; 
          &:hover{
            color: #fe2e54;
          }
        }
    }
  }
}

.act-bottom{
  position: fixed;  z-index: 90; left: 0; bottom: 0;
  background: rgba(0,0,0,.5);
  overflow: hidden;
  width: 100%;
  .pic{
    width: 3000px; left: 50%; position: relative; z-index: 0;
    margin-left:-1500px;
    display: flex;
    justify-content: center; align-items: center;
    img{
      display: block; margin: 0;
    }
    .close{
      position: absolute; color: #fff; top: 0; left: 50%; z-index: 0; cursor: pointer;
      margin-left: 590px;
      i{ font-size: 24px;}
    }
  }
}

.act-left{
  position: fixed; left: 50%;
}

.QRCode{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .8);
  display: flex;
  justify-content: center;
  align-items: center;
  &>div{
    background: #fff;
    padding:5px;
    border-radius: 10px;
    text-align: center;
     line-height: 25px;
     color: #666;
    .code{
      margin-bottom:5px;
      width: 180px;
      height: 180px;
    }
  }
}
.float-tool {
  position: fixed;
  z-index: 99;
  bottom: 70px;
  right: 50%;
  margin-right: -725px;
  border: 1px solid rgba(0, 0, 0, .7);
  .zsMarket_wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding-top: 0;
    p{
      width: 35px;
      &:nth-of-type(1){
        margin-bottom: 5px;
      }
    }
  }
  .modal_container_one_shop{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding-top: 0;
    p{
      &:nth-of-type(1){
        margin-bottom: 5px;
      }
    }
    &:hover{
      .oneShop{
        opacity: 1;
      }
    }
    .oneShop{
      position: absolute;
      background: url(./images/one.png) no-repeat center center;
      background-size: 100% auto;
      width: 147px;
      height: 80px;
      right: 100%;
      top: -10px;
      opacity: 0;
    }
  }
  .zsMarket_wrap_act{
    // background: rgba(0,0,0,.6);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding-top: 0;
    p{
      width: 35px;
      &:nth-of-type(1){
        margin-bottom: 5px;
      }
    }
  }
  .service{
    .codeCon{
      width: 128px;
      height: 145px;
      position: absolute;
      margin: -90px 0 0 -152px;
      padding: 10px;
      padding-right: 20px;
      z-index: 10;
      background: url('./images/kefubj.png') no-repeat;
      background-size: 100% 100%;
      display: none;
    }
    .code{
      font-size: 12px;
      color: #EA2C13;
      text-align: center;
      padding-top: 10px;
      box-sizing: border-box;
      img{
        width: 100px;
        height: 100px;
        margin-bottom: 5px;
      }
    }
  }
  .service:hover{
    .codeCon{
      display: block;
    }
  }
}
@media screen and (max-width: 1465px) {
  .float-tool {
    right: 0;
    margin-right: 20px;
  }
}
.float-tool li {
  width: 44px;
  height: 55px;
  background: rgba(0,0,0,0.7);
  border-top: 1px solid rgba(255,255,255,0.1);
  text-align: center;
  padding-top: 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  position: relative;
}
.float-tool li a {
  color: #fff;
}
.float-tool li:hover {
  background: rgba(0,0,0,0.9);
}
.float-tool li p {
  font-size: 12px;
  line-height: 12px;
}
.float-tool li:first-child {
  border: none;
}
.float-tool li img {
  width: 20px;
  height: 20px;
}
.float-tool li .sc_modal{
  position: absolute;
  left: -150px;
  top: 0;
  width: 147px;
  height: 78px;
}
.float-tool li .modal_container{
  background: url('./images/zsMarket_bg.png') no-repeat;
  background-size: 100% 100%;
  width: 147px;
  height: 184px;
  position: absolute;
  left: -150px;
  top: -60px;
  padding: 14px 20px;
  box-sizing: border-box;
  .tip{
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #FE2E54;
    padding-right: 8px;
    margin-top: 8px;
  }
}

.modal_container_hover{

  &:hover .modal_container_two{
    display: block;
    z-index:10;
  }
}
.modal_container_two{
  
  position: absolute;
  right: 100%;
  // margin-right: 10px;
  z-index:0;
  display: none;
  &>div{
    position: relative;
    z-index: 1;
    width: 128px;
    // height: 183px;
    box-sizing: border-box;
    background: #EA2C13;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.20);
    padding-top:10px;
    border-radius:6px;
    right:10px;

    padding-bottom:1px;
    &:before{
      content: '';
      width: 0;
      height: 0;
      border-left: 7px solid #EA2C13;
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      position: absolute;
      left: 100%;
      bottom: 50%;
      margin-left: 0px;
      margin-bottom:-5px;
    }
  }
  a{
    
    // height: 50px;

    display: block;
    margin:auto 10px 6.5px 10px;
    img{
      width:100%; 
      border:0;
      height:auto;
    }
    &:hover{
      opacity: .95;
    }
  }
}

.float-tool li.ft-share {
  position: relative;
}
.float-tool li.ft-share .share-platform {
  position: absolute;
  right: 0;
  top: 13px;
  width: 200px;
  display: none;
}
.float-tool li.ft-share:hover .share-platform {
  display: block;
}

.mark{
    width:100%;
    height:100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #000;
    opacity: .4;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    z-index: 1001;
}
.share-dialog{
    position: fixed;
    left: 50%;
    top: 50%;
    width: 500px;
    height: 270px;
    margin:-135px 0 0 -250px;
    background: #fff;
    z-index: 1002;
}

.share-dialog .share-dialog-title{
    height: 45px;
    line-height: 45px;
    text-indent: 15px;
    background: #eee;
}
.share-dialog .share-dialog-cont{
    padding:38px 20px;
    font-size: 14px;
}
.share-dialog .share-copy-l{
    float: left;
    width: 77px;
    margin-top: 8px;
}
.share-dialog .share-copy-c{
    float: left;
    width: 307px;
}
.share-dialog .share-copy-c input{
    display: block;
    width: 100%;
    height: 36px;
    padding: 7px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    line-height: 1.2;
    color: #555;
    vertical-align: middle;
    background: #fff;
    border: 1px solid #ccc;
}
.share-dialog .share-copy-c input:focus{
    border-color: #333;
    outline: 0;
}
.share-dialog .share-copy-r{
    float: left;
    width: 76px;
    text-align: right;
    color: #f37b1d;
    margin-top: 8px;
    cursor: pointer;
}
.share-platform{
    clear: both;
    margin-top:-8px;
}
.share-platform .share-platform-l{
    float: left;
    width: 77px;
    margin-top: 8px;
}
.share-platform .share-platform-r{
    float: left;
    width: 160px;
}
.share-platform-r .bshare-custom .bds_weixin{
    background-image: url('./images/css-bg.png');
    background-position: -13px -7px !important;
}
.share-platform-r .bshare-custom .bds_tsina{
    background-image: url('./images/css-bg.png');
    background-position: -57px -7px !important;
}
.share-platform-r .bshare-custom .bds_sqq{
    background-image: url('./images/css-bg.png');
    background-position: -101px -7px !important;
}
.share-platform-r .bshare-custom .bds_qzone{
    background-image: url('./images/css-bg.png');
    background-position: -146px -7px !important;
}
.share-platform .share-platform-text{
    color: #aaa;
    margin-top: 5px;
}

.share-platform-r .bdshare-button-style1-32 a {
    float: left;
    width: 32px;
    line-height: 32px;
    height: 32px;
    // background-image: url(../img/share/icons_1_32.png?v=83ba0265.png);
    // _background-image: url(../img/share/icons_1_32_debase.png?v=0de29fb2.png);
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 6px 6px 6px 0;
    text-indent: -100em;
    overflow: hidden;
    color: #3a8ceb;  
    padding:0;
}
</style>
